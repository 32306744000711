<script lang="ts">
    import { onMount } from "svelte";
    import { Splide, SplideSlide } from "@splidejs/svelte-splide";
    import "@splidejs/svelte-splide/css/core";

    let slides = [
        {
            img: "printer",
            text: "Ремонт и прошивка принтеров",
        },
        {
            img: "delivery",
            text: "Доставка новых недорогих катриджей",
        },
        {
            img: "piggy-bank",
            text: "Бесплатная замена чипа в катридже",
        },
        {
            img: "fast-delivery",
            text: "Бесплатный срочный выезд мастера",
        },
        {
            img: "diploma",
            text: "Спец. условия для организаций",
        },
        {
            img: "atm-card",
            text: "Заправим в долг катриджи по безналу",
        },
    ];
</script>

<div class="px-2 py-4 bg-theme-100 absolute left-0 top-[60px] w-full">
    <Splide options={{ type: "loop", autoplay: true }}>
        {#each slides as slide, i (i)}
            <SplideSlide>
                <div class="flex w-full gap-2 justify-center">
                    <div class="w-6 h-6">
                        <img
                            src="/assets/img/icons/{slide.img}.png"
                            alt={slide.text}
                        />
                    </div>
                    <div class="text-theme-400 whitespace-nowrap">{slide.text}</div>
                </div>
            </SplideSlide>
        {/each}
    </Splide>
</div>

<style>
    :global(.splide__arrows, .splide__pagination) {
        display: none;
    }
</style>
