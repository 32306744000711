<script>
    import {go} from "../stores/storeRoute";
    import {fade} from "svelte/transition";

    const toHome = () => {
        go('form1')
    }
</script>
<div in:fade={{duration: 300, delay: 200}} out:fade={{duration: 300}}>
<button class="bg-theme-100 w-full rounded-lg p-2 mt-3 text-black font-bold" on:click={toHome}>На главную</button>
<div class="mt-2"></div>
<article class="prose prose-sm sm:prose lg:prose-lg xl:prose-xl mx-auto">
    <h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>
    <p>
        Настоящая Политика конфиденциальности персональной информации (далее — Политика) действует в отношении
        информации, которую администрация настоящего приложения (далее — АДМИНИСТРАЦИЯ) может получить о пользователе
        (далее — Клиенте) во время использования им настоящего приложения (далее — Приложение).
    </p>
    <p>
        Политика применима только к Приложению. АДМИНИСТРАЦИЯ не контролирует и не несет ответственность за приложения
        третьих лиц, на которые Клиент может перейти по ссылкам, доступным из Приложения, в том числе за обрабатываемую
        третьими лицами информацию о Клиенте.
    </p>
    <p>
        Использование Приложения означает безоговорочное согласие Клиента с настоящей Политикой и указанными в ней
        условиями обработки его персональной информации; в случае несогласия с этими условиями Клиент должен
        воздержаться от использования Приложения.
    </p>

    <h3>1. Информация Клиентов, которую получает и обрабатывает АДМИНИСТРАЦИЯ</h3>
    <p>
        1.1. В рамках настоящей Политики под персональной информацией Клиента понимаются:
    </p>
    <p>
        1.1.1. Персональная информация, которую Клиент предоставляет о себе самостоятельно при регистрации в Приложении
        или в процессе использования Приложения, включая персональные данные Клиента, передаваемые в ходе исполнения
        любых соглашений АДМИНИСТРАЦИИ с Клиентом. Следует учесть, что использование Клиентом определенных сервисов
        Приложения возможно только в случае предоставления необходимых данных.
    </p>
    <p>
        1.1.2. Данные, которые автоматически передаются АДМИНИСТРАЦИИ в процессе использования Приложения с помощью
        установленного на устройстве Клиента программного обеспечения, в том числе IP-адрес, информация из cookie
        (текстовые файлы, хранящиеся в браузере Клиента), информация о браузере Клиента (или иной программе, с помощью
        которой осуществляется доступ к Приложению), время доступа, адрес запрашиваемой страницы.
    </p>
    <p>
        1.1.2.1. При использовании Приложения происходит автоматический сбор (из cookies) следующих обезличенных
        статистических данных о Клиенте, в том числе:
    </p>
    <ul>
        <li>тип выполненного в Приложении действия (клик, наведение курсора и т.п.);</li>
        <li>дата и время выполнения действия;</li>
        <li>IP;</li>
        <li>данные о фактах заполнения форм/обращений в Приложении.</li>
    </ul>
    <p>
        1.1.2.2. Пользуясь Приложением, Клиент (в том числе посетитель Приложения) соглашается на то, что АДМИНИСТРАЦИЯ
        может использовать статистические данные и файлы сookies, и может передавать третьему лицу для проведения
        исследований, выполнения работ или оказания услуг АДМИНИСТРАЦИИ. Клиент (в том числе посетитель Приложения)
        может самостоятельно управлять файлами сookies путем изменения настроек браузера, функционирующего на его
        оборудовании. Изменения пользовательских настроек, в результате которых файлы cookies будут заблокированы, могут
        привести к недоступности отдельных компонентов Приложения.
    </p>
    <p>
        1.1.3. АДМИНИСТРАЦИЯ может получить общедоступную информацию, когда Клиент использует сторонние ресурсы
        (например, чаты/формы/социальные сети). Эти данные могут содержать информацию, которую Клиент публикует, в том
        числе в виде комментариев или отзывов о сервисах Приложения. АДМИНИСТРАЦИЯ использует такие сведения в целях
        повышения качества обслуживания Клиентов.
    </p>
    <p>
        1.1.4. АДМИНИСТРАЦИЯ также может обрабатывать определенные данные (например, IP-адрес, идентификатор
        пользовательского устройства) для выявления и (или) предотвращения условий, способствующих совершению с
        использованием Приложения действий, противоречащих требованиям законодательства.
    </p>
    <p>
        1.1.5. АДМИНИСТРАЦИЯ может получать информацию о Клиенте от своих контрагентов. Например, при исполнении
        соглашения с контрагентом, последний может передавать АДМИНИСТРАЦИИ некоторые сведения, которые обеспечивают
        контрагенту возможность установления связи между таким Клиентом и его заявкой, информация о совершении которого
        передается АДМИНИСТРАЦИЕЙ контрагенту.
    </p>
    <p>
        1.2. За исключением случаев идентификации/упрощенной идентификации Клиентов по основаниям и в порядке,
        предусмотренным применимыми соглашениями Клиента и АДМИНИСТРАЦИИ, АДМИНИСТРАЦИЯ не проверяет достоверность
        персональной информации, предоставляемой Клиентами, и не осуществляет контроль за их дееспособностью. Однако
        АДМИНИСТРАЦИЯ исходит из того, что Клиент предоставляет достоверную и достаточную персональную информацию и
        поддерживает эту информацию в актуальном состоянии.
    </p>

    <h3>2. Цели сбора и обработки персональной информации Клиентов</h3>
    <p>
        2.1. АДМИНИСТРАЦИЯ собирает и хранит только ту персональную информацию, которая необходима для использования
        Приложения или исполнения соглашений с Клиентом, за исключением случаев, когда законодательством предусмотрено
        обязательное получение и хранение определенной персональной информации. Хранение персональной информации
        осуществляется не дольше, чем этого требуют цели обработки, если только срок хранения такой персональной
        информации не установлен законодательством РФ, соглашением об использовании Клиентом Приложения.
    </p>
    <p>
        2.2. Персональную информацию Клиента АДМИНИСТРАЦИЯ может использовать в следующих целях:
    </p>
    <ul>
        <li>Исполнения соглашений с АДМИНИСТРАЦИЕЙ, в том числе в целях проведения идентификации/упрощенной
            идентификации Клиента, выполнения распоряжений Клиента;
        </li>
        <li>Коммуникаций. Осуществление связи с Клиентом, в том числе направления уведомлений, запросов и информации,
            касающихся использования Приложения, оказания АДМИНИСТРАЦИЕЙ услуг, а также обработки запросов и заявок от
            Клиента;
        </li>
        <li>Улучшения качества Приложения, удобства его использования, разработки новых сервисов Приложения, предложения
            Клиенту персонализированных сервисов;
        </li>
        <li>Проведения статистических и иных исследований на основе обезличенных данных;</li>
        <li>Проведения маркетинговых акций для Клиентов, в том числе в целях распространения предложений об участии в
            акции и получения предусмотренных акцией призов/вознаграждений; распространения рекламно-информационных
            материалов по сетям электросвязи, в том числе посредством использования телефонной, факсимильной, подвижной
            радиотелефонной связи, или путем прямых контактов; таргетирования рекламных материалов и иной информации,
            доводимой до сведения Клиентов;
        </li>
        <li>Предотвращение условий, способствующих совершению с использованием Приложения действий, противоречащих
            требованиям законодательства.
        </li>
    </ul>

    <h3>3. Условия обработки персональной информации Клиента и её передачи третьим лицам</h3>
    <p>
        3.1. АДМИНИСТРАЦИЯ обрабатывает персональную информацию Клиентов в соответствии с настоящей Политикой, условиями
        оказания конкретных услуг Приложения и его внутренними регламентами.
    </p>
    <p>
        3.2. В отношении персональной информации Клиента обеспечивается ее конфиденциальность.
    </p>
    <p>
        3.3. АДМИНИСТРАЦИЯ вправе передать персональную информацию Клиента третьим лицам в следующих случаях:
    </p>
    <ul>
        <li>Клиент выразил свое согласие на такие действия;</li>
        <li>Передача необходима для использования определенного сервиса Приложения или приложения-партнера, в том числе
            для исполнения распоряжения Клиента.
        </li>
        <li>Персональная информация может быть передана третьим лицам следующих категорий:</li>
        <ul>
            <li>Кредитные организации и иные организации — контрагенты;</li>
            <li>Партнеры по маркетингу и иные контрагенты, с которыми у АДМИНИСТРАЦИИ есть соглашение об оказании
                услуг;
            </li>
            <li>В рамках передачи Приложения и его функций третьему лицу, включая передачу прав, обязанностей и
                обязанностей в рамках соглашений, заключенных с Клиентом, новому владельцу;
            </li>
            <li>В случаях, когда передача предусмотрена применимыми нормами законодательства РФ.</li>
        </ul>
    </ul>
</article>
<div class="mt-2"></div>
<button class="bg-theme-200 w-full rounded-lg p-2 mt-3 text-black font-bold" on:click={toHome}>На главную</button>
</div>
