<script lang="ts">
    import {fade} from "svelte/transition";
    import storeRoute, {go} from "../stores/storeRoute";
    import Slider from "../components/Slider.svelte";
    import {onMount} from "svelte";
    import {loadStoreForm} from "../stores/storeForm";

    const next = () => {
        storeRoute.set("form2");
    };

    const phone = import.meta.env.VITE_PHONE
    const phoneFormated = import.meta.env.VITE_PHONE_FORMATED
    const url_website = import.meta.env.VITE_URL_WEBSITE

    onMount(() => {
        loadStoreForm()
    })
</script>


<div in:fade={{duration: 300, delay: 200}} out:fade={{duration: 300}}>
    <Slider/>

    <div class="mt-16"></div>

    <div class="w-full flex justify-center font-bold text-[#bb261f] text-center">
        <span>Скидка 10% при заказе через приложение!</span>
    </div>


    <div class="flex flex-col w-full justify-center items-center mt-4">
        <button on:click={next}
                class="aspect-square w-[80%] bg-theme-100 shadow shadow-hover rounded-full flex flex-col justify-center items-center">
            <img class="w-[40%]" src="/assets/img/order.webp" alt="order" style="transform: translateX(12px);">
            <span class="text-[40px] text-theme-400">Заказать</span>
        </button>
        <a href="tel:{phone}"
           class="aspect-square w-[30%] bg-call shadow shadow-hover rounded-full flex flex-col justify-center items-center mt-6">
            <img class="w-[50%]" src="/assets/img/phone.webp" alt="phone">
        </a>
    </div>

    <div class="flex flex-col gap-2 text-xs text-white items-center mt-10 text-center">
        <a href="tel:{phone}">{phoneFormated}</a>
        <a href="{url_website}" target="_blank" rel="noopener noreferrer">{url_website}</a>
        <a href="{url_website}" target="_blank" rel="noopener noreferrer">Работаем без выходных с 8:00 до 21:00</a>
        <button on:click={() => go('policy')} class="mt-4">Используя это приложение, вы соглашаетесь с <span class="font-bold text-[#1260a4]">Политикой Конфиденциальности</span></button>
    </div>
</div>
